




























































































































































import { SfLoader, SfNotification, SfHeading, SfButton, SfProperty, SfPrice } from '@storefront-ui/vue';
import { computed, defineComponent, ref, watch, useRouter, useContext, onMounted } from '@nuxtjs/composition-api';
import { useProduct, cartGetters, useExternalCheckout } from '@vue-storefront/magento';
import { AgnosticDiscount } from '@vue-storefront/core';
import { useCart } from '~/composables';

import { useUser } from '~/composables';
import CouponCode from './CouponCode.vue';
import EmptyCartAlternativeProducts from './EmptyCartAlternativeProducts.vue';
import GiftWrapping from './GiftWrapping.vue';
import Sidebar from '../Sidebar/Sidebar.vue';
import EmptyCartImage from '~/assets/images/empty-cart.svg';
import { useExponeaStore, useCartStore, useUiState } from '~/stores';
import useExponeaConstants from '~/constants/exponea';
import useConstants from '~/constants';
import { storeToRefs } from 'pinia';
import CartSidebarItem from './CartSidebarItem.vue';
import ShinyCombination from './ShinyCombination.vue';

export default defineComponent({
  name: 'CartSidebar',
  components: {
    CartSidebarItem,
    SfLoader,
    SfNotification,
    SfButton,
    SfHeading,
    SfProperty,
    SfPrice,
    CouponCode,
    GiftWrapping,
    Sidebar,
    EmptyCartImage,
    EmptyCartAlternativeProducts,
    ShinyCombination,
  },
  setup() {
    const { initializeCheckout } = useExternalCheckout();
    const uiState = useUiState();
    const { isCartSidebarOpen } = storeToRefs(uiState);
    const { toggleCartSidebar } = uiState;
    const cartStore = useCartStore();
    const { cart, products } = storeToRefs(cartStore);
    const router = useRouter();
    const { app } = useContext();
    const { loading } = useCart();
    const { products: alternativeProductsResult } = useProduct('productsYouMightLike');
    const { isAuthenticated } = useUser();

    const subtotal = computed(() => cart.value?.prices?.subtotal_including_tax?.value || 0);
    const total = computed(() => cart.value?.prices?.grand_total?.value || 0);

    const { MINIMUM_TOTAL_FOR_FREE_SHIPPING } = useConstants();

    const discounts = computed(
      () =>
        cart.value?.prices?.discounts?.map(
          (d) =>
            ({
              id: d.label,
              name: d.label,
              description: '',
              value: d.amount.value,
              code: d.label,
            } as AgnosticDiscount),
        ) || [],
    );

    const totalProductsDiscount = computed(() => {
      const totalRegularPrice = cart.value?.items?.reduce(
        (acc, item) => acc + item.product.price_range.maximum_price.regular_price.value * item.quantity,
        0,
      );
      const totalFinalPrice = cart.value?.items?.reduce(
        (acc, item) => acc + item.product.price_range.maximum_price.final_price.value * item.quantity,
        0,
      );
      return Math.round((totalRegularPrice - totalFinalPrice) * 100) / 100;
    });

    const cartProductAmount = computed(() => cartGetters.getTotalItems(cart.value));
    const shippingCost = computed(() => cartGetters.getShippingPrice(cart.value));
    const shippingMethod = computed(() => cartGetters.getSelectedShippingMethod(cart.value));
    const notificationVisible = ref(false);
    const notificationMessage = ref<string | null>(null);
    const cartLoaded = ref(false);
    const checkoutDisabled = computed(() => {
      const out_of_stock_items = cart.value?.items?.filter(
        (item: any) => item.configured_variant?.stock_status === 'OUT_OF_STOCK',
      );
      return out_of_stock_items?.length || loading.value;
    });

    const loadCartOrAlternativeProducts = async () => {
      if (!cart.value?.items?.length) {
        await exponeaStore.getRecommendation({ id: EMPTY_CART_RECOMMENDATIONS_ID, fillWithRandom: true, size: 6 });
      }
      cartLoaded.value = true;
    };

    watch(isCartSidebarOpen, () => {
      if (isCartSidebarOpen.value && !cartLoaded.value) {
        loadCartOrAlternativeProducts();
      }
    });

    const goToCheckout = async () => {
      let baseUrl = '/checkout/';

      if (isAuthenticated.value) {
        baseUrl = '/checkout/';
      }
      const redirectUrl = await initializeCheckout({ baseUrl });
      await router.push(`${app.localePath(redirectUrl)}`);
    };

    const showAddedGiftwrapNotification = (product) => {
      notificationMessage.value = app.i18n.t('You added {product} to your shopping cart.', {
        product: product?.name,
      }) as string;
      notificationVisible.value = true;

      setTimeout(() => (notificationVisible.value = false), 3000);
    };

    // Exponea recommended products
    const exponeaStore = useExponeaStore();
    const { EMPTY_CART_RECOMMENDATIONS_ID } = useExponeaConstants();
    const exponeaRecommendations = computed(() =>
      exponeaStore.getExponeaRecommendationById(EMPTY_CART_RECOMMENDATIONS_ID),
    );

    const priceTotalCalculated = computed(() => {
      return productsWithPrices.value?.reduce((acc, item) => acc + item.regularPrice, 0);
    });

    const productsWithPrices = computed(() => {
      return products.value?.map((product) => {
        const minimumPrice = product.product.price_range.minimum_price.regular_price.value * product.quantity;
        const totalIncludingTax = product.prices.row_total_including_tax.value;

        return {
          ...product,
          regularPrice: (totalIncludingTax < minimumPrice && minimumPrice) || totalIncludingTax,
          specialPrice: (totalIncludingTax < minimumPrice && totalIncludingTax) || null,
        };
      });
    });

    return {
      isCartSidebarOpen,
      notificationVisible,
      toggleCartSidebar,
      goToCheckout,
      total,
      subtotal,
      cartProductAmount,
      discounts,
      cart,
      shippingCost,
      shippingMethod,
      alternativeProductsResult,
      exponeaRecommendations,
      MINIMUM_TOTAL_FOR_FREE_SHIPPING,
      showAddedGiftwrapNotification,
      notificationMessage,
      priceTotalCalculated,
      productsWithPrices,
      cartLoaded,
      checkoutDisabled,
      totalProductsDiscount,
    };
  },
});
